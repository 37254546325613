import FrameEntryModel from "./models/frame-entry.model"
import './box-label-alloy.css'
import FrameImage from "./frame-image"
import FrameLookupDTO from "./models/frame-lookup.dto"
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
import { ReactBarcode } from 'react-jsbarcode';

interface IBoxLabelProps {
      frameLookup: FrameLookupDTO,
      boxedBy: string, // looked up in the history after it loads
      boxedDate: string,  // looked up in the history after it loads
}
//<div className='text-4xl p-2' >  {props.frameLookup.frame?.name} {props.frameLookup.frame?.size}"</div>
//AP021252115
export const BoxLabelAlloy = (props: IBoxLabelProps) => {
      //                          labelWidthMillimeters: 101.6,  //, // 6.88"
      //                            labelHeightMillimeters:  101.6,
      const [yearRange, setYearRange] = useState('');
      const [colorBarBackgroundColor, setColorBarBackgroundColor] = useState('');

      /*
                  let dateStringRange = serial.index(serial.startIndex, offsetBy: 2) ..< serial.index(serial.endIndex, offsetBy: -4)
            let dateString = serial[dateStringRange];
            let dayOfYearRange = dateString.index(dateString.startIndex, offsetBy: 0) ..< dateString.index(dateString.endIndex, offsetBy: -2)
            let yearRange = dateString.index(dateString.startIndex, offsetBy: 3) ..< dateString.endIndex
            
            let dayOfYear = dateString[dayOfYearRange]
            let year = dateString[yearRange]
      */

      function is2025(): boolean {
            let serial = props.frameLookup.frame?.serialId;
            const dateString = serial?.substring(2, serial.length - 4)
            const dayOfYear = dateString!.substring(0, dateString!.length - 2);
            let year = dateString?.substring(3);
            if (parseInt(year!)! >= 25) {
                  return true;
            } else if (parseInt(dayOfYear)! >= 351 && parseInt(year!)! === 24) {
                  return true;
            } else {
                  return false;
            }
      }

      useEffect(() => {
            function getYearLabelBackgroundColor() {
                  // 32 inch will work for all years so don't color the background
                  if (props.frameLookup.frame?.size.startsWith("32") === true) {
                        setColorBarBackgroundColor("#EEEEEE")
                  } else if (yearRange === "2021-2025") {
                        setColorBarBackgroundColor("#57b9ff") // darker light blue
                  } else if (yearRange === "2021-2024") {
                        setColorBarBackgroundColor("#8FF703") //green                  
                  } else if (yearRange === "2017-2020") {
                        setColorBarBackgroundColor("#FF0d83")
                  } else {
                        setColorBarBackgroundColor("#FFFFFF") // unknown
                  }
            }
            getYearLabelBackgroundColor()
      }, [yearRange]) // <-- empty dependency array

      useEffect(() => {
            function getYearRange(): string {

                  if (props.frameLookup.frame?.year === 2020) {
                        setYearRange("2017-2020")
                        return "2017-2020"
                  } else if (props.frameLookup.frame?.year === 2021) {
                        if (is2025() === true) {
                              setYearRange("2021-2025")
                              return "2021-2025"
                        } else {
                              setYearRange("2021-2024")
                              return "2021-2024"
                        }
                  } else {
                        setYearRange(props.frameLookup.frame!.year!.toString())
                        return props.frameLookup.frame!.year.toString()
                  }

            }
            getYearRange();
      }, []) // <-- empty dependency array


      return (
            <div className="box-label-alloy flex flex-column">
                  <div className="flex flex-row">
                        <div className="left-description-bar flex">
                              <img  src={process.env.PUBLIC_URL + `/img/alloy-deco-bezels.png`} alt="alloy-deco-bezels" />
                              <div className="frame-profile">{props.frameLookup.frame?.profile ?? "not found"}</div>
                              <div className="frame-style">{props.frameLookup.frame?.name ?? "not found"}</div>
                        </div>

                        <div className="right-image-bar flex flex-column">
                              <div className="qr-code">
                                    <QRCode 
                                          format={"CODE128"} 
                                          value={`https://decotvframes.com/pages/myframe?sr=${props.frameLookup.frame!.serialId!}`} 
                                          size={80}
                                    />
                              </div>


                              <div className="frame-image">
                             
                                    <img 
                                           src={process.env.PUBLIC_URL + `/img/alloy-label/${props.frameLookup.frame?.image?.toLowerCase().replaceAll('_', '-') ?? "NOT_FOUND.jpg"}-label.jpg`} 
                                          alt={props.frameLookup.frame?.image ?? "NOT_FOUND"} />



                                    {props.frameLookup.isVoid === true &&
                                          <div className='voided text-red-600 text-7xl'> Voided </div>
                                    }
                              </div>
                              <div className="style-short-code" style={{color: is2025() === true  ? "#57b9ff": "black" }}>
                                    {props.frameLookup.frame?.profile!.charAt(0)}                                 
                              </div>
                              <div className="samsung2025" style={{color: is2025() === true  ? "#57b9ff": "black" }}>
                                    { is2025() && 
                                          <>
                                          <div>Compatible with Samsung</div>
                                          <div>"The Frame" 2021-2025</div>
                                          </>
                                    }
                              </div>


                        </div>
                  </div>
                  <div className="bottom-bar flex-column">
                        <div className="flex flex-row">
                              <div className="bottom-bar-description-left flex-column">
                                    <div className="bottom-bar-line"></div>
                                    <div style={{color:"gray"}}>SKU: <span style={{color:"black"}}>{props.frameLookup.frame?.skuInternal}</span></div>
                                    <div style={{color:"gray", fontSize:"1.2rem"}}>SN: <span style={{color:"black"}}>{props.frameLookup.frame?.serialId}</span></div>
                                    <ReactBarcode style={{ height: "119px"  }} options={{ height: 50 }} value={props.frameLookup.frame!.barCode} />
                              </div>
                              <div className="bottom-bar-size-right flex-column">

                                    <div className={`frame-size size-${props.frameLookup.frame!.size}`}>
                                          {props.frameLookup.frame?.size}"
                                    </div>
                              </div>
                        </div>
                        <div className="bottom-bar-strip flex-column">
                              <div className="flex flex-column">
                                    <div className="fits">Proudly boxed by: {props.boxedBy ?? ""} {props.boxedDate  ?? ""}</div>
                              </div>
                        </div>
                  </div>


            </div>

      )

}