import { useEffect, useState } from "react"
import { User } from "../../models/user"
import useFetch from "../../hooks/use-fetch";
import { DateTime } from "luxon";
import Config from "../../config";
import './user-list.css';

interface IUserListProps {
      startDate: DateTime | null;
      endDate: DateTime | null;
      excludeIncludeUser: ( user: User, exclude: boolean ) => void; 
}

export const UserListView = ( props: IUserListProps ) => {

      const [startDate, setStartDate] = useState<DateTime | null>( DateTime.now().startOf('month') );
      const [endDate, setEndDate] = useState<DateTime | null>(DateTime.now());

      const url = startDate && endDate && `${Config.baseUrl}/api/admin/reports/production/average-time-per-frame/daily/users?startdate=${props.startDate?.toFormat('yyyy-MM-dd') + 'T00:00:01'}&enddate=${props.endDate?.toFormat('yyyy-MM-dd') + 'T23:59:59'}`;

      const { data, loading, error } = useFetch<User[]>(url ?? "", [], {});

      function hasData(x: any) {
            return x && x.length > 0 ? true : false;
      }
      const [userList, setUserList] = useState<User[] | null>(null);

      useEffect(() => {

            function getData() {
                  if (!data) {
                        return
                  }
                  if (data.length === 0) {
                        return
                  }
                  for( const u of (data as any).users ){
                        u.excluded = false;
                  }
                  setUserList( (data as any).users );
            }
            getData()

      }, [data]);

      function removeUserFromList( selectedUser: User, exclude: boolean) {
            setUserList( users => {      
                  if (!users ) return [];
                  let userFound = users!.find( u => u.userId === selectedUser.userId)
                  userFound!.excluded = exclude ?? true;


                  let includedUsers = users!.filter( user => user.userId !== selectedUser.userId );
                  includedUsers.push( userFound! );
                  let excluded = includedUsers.filter( user => user.excluded === true ).sort( (a,b)=> a.userName.toLowerCase().localeCompare(b.userName.toLowerCase() )  );
                  let included = includedUsers.filter( user => user.excluded === false ).sort( (a,b)=> a.userName.toLowerCase().localeCompare(b.userName.toLowerCase() )  );
                  props.excludeIncludeUser( userFound!, exclude );
                 return [...included!, ...excluded!]
            })
      }

      return (
            <>
            <div>People with time logged during the period (click a name to exclude their hours)</div>
            <div className="user-list-wrapper flex flex-row flex-wrap">                  
                        { userList && userList.length > 0  && userList.map((user: User) => {
                              return (
                                    <div className='user-entry' key={user.userId} style={{backgroundColor: user.excluded === true ? "pink" : "#e8e8e8"}}  onClick={() => removeUserFromList(user, user.excluded ? false : true)}>
                                          <div className={ `flex flex-row `} > {user.userName} </div>
                                    </div>
                              )
                        })}
            </div>
            </>
      )


}
