import useFetch from "../../hooks/use-fetch";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import Config from "../../config";
import {GridComponent} from 'echarts/components'
import { BarSeriesOption, EChartsOption, SeriesOption } from "echarts/types/dist/echarts";
import { ReactECharts } from "../../echarts/react-echarts";
import NextPreviousDayButtons from "../components/next-previous-day-buttons";
import AverageTimePerFrameChartOptions from "./average-time-per-frame-chart-options";
import { useLocation } from 'react-router-dom';
import { DurationUtils } from "../../utils/duration-utils";
import { Duration } from "../../models/duration";
import { Panel } from "primereact/panel";
import { DateUtils } from "../../utils/date-utils";
import { Dropdown } from "primereact/dropdown";
import { ListBox, ListBoxChangeEvent } from "primereact/listbox";
import DateRangePicker from "../../date-pickers/date-range-picker"
import { DateTime } from "luxon";
import { UserListView } from "./user-list";
import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";

interface IUserPerformanceResult {
      stationId: number,
      stationName: string,
      stationFrameType: string;
      entries: IUserPerformance[]
}

interface IUserPerformance {
      firstname: string
      lastname: string
      userId: string;
      totalFramesCompleted: number,
      totalFrameTime: Duration;
      totalStationTime: Duration;
      avgTimePerFrame: Duration;
      avgTimePerFrameStation: Duration;
      stationId: string;
      stationName: string;
      stationFrameType: string;
      date: string;

}

interface IPerformanceChartPerStation {

      eBarChartOptions: EChartsOption;
      rawData: IUserPerformance[];
      //  entries: IUserStationRow[];
}
interface IUserStationRow {
      userName: string
      stationName: string
      stationFrameType: string;
      percentage: number;
      date: string;

}

interface IDailyAverage {
      date: string; 
      totalFrames: number;
      totalPremiere: number;
      totalAlloy: number;
      totalMinutes: number;
      totalHoliday: number;
      totalPto: number;
      totalWork: number;
      accumulativeFrames: number;
      accumulativeMinutes: number;
      eBarChartOptions: EChartsOption;
      rawData: IUserPerformance[];
      //  entries: IUserStationRow[];
}

interface ISeriesEntry {
      yAxisIndex: number, 
      name: string;//'Direct',
      type: string; //'bar',
      stack: string; //'total',
      color: string;
      label: {
            show: true,
            formatter: any //()// {
            //          return "10% (30 frames total) \n xxxxxxxxxxxx"
            //      }
      },
      emphasis: {
            focus: string //'series'
      },
      data: number[] //[320]
}

interface User{
      userId: string,
      userName: string
}
interface UserData{
      userId: string,
      userData: IDailyAverage[] | null
}
const AverageTimePerFrameReport = () => {
      const location = useLocation()
      const queryParameters = new URLSearchParams(location.search)

      const [eBarChartOptions, setEBarChartOptions] = useState<EChartsOption>()
      const [selectedUsers, setSelectedUsers] = useState<User[]>()
      const [selectedStation, setSelectedStation] = useState<{stationId: string, stationName: string}[]>()

      const [userExcludeList, setUserExcludeList] = useState<User[]>([] );
      const [exludeUserIdParams, setExcludeUserIdParams] = useState<string>( "" );

      const [startDate, setStartDate] = useState<DateTime | null>( DateTime.now().startOf('month') );
      const [endDate, setEndDate] = useState<DateTime | null>(DateTime.now());

      const [range, setRange] = useState< "day" | "weekly" | "month" | "quarter" >("day")

      

      const url = range && startDate && endDate && userExcludeList && `${Config.baseUrl}/api/admin/reports/production/average-time-per-frame/daily?startdate=${startDate.toFormat('yyyy-MM-dd') 
                                                            + 'T00:00:01'}&enddate=${endDate.toFormat('yyyy-MM-dd') + 'T23:59:59'}&range=${range}${exludeUserIdParams}`;
      const { data, loading, error } = useFetch<IDailyAverage[]>(url ?? "", [], {});

      const rangeOptions: string[] = ['day', "week", 'month', "quarter"];

      function hasData(x: any) {
            return x && x.length > 0 ? true : false;
      }

      useEffect(() => {

            function getData() {
                  if (!data) {
                        return
                  }
                  if (data.length === 0) {
                        return
                  }
                  let charts: IPerformanceChartPerStation[] = [];

  
//                  https://coolors.co/bd9971-0267c1-84d2f6-e54b4b-cc5803
                  const colors = { 
                                    frames: '#bd9971', 
                                    premiere: "#46201a",
                                    alloy: "gray",
                                    hours: '#0267C1', 
                                    averageTimePerDay: '#E54B4B',  
                                    accumulativeAverage: '#84D2F6'                 
                  };

                  const days = data.map( e=> e.date);
                  const chart = {
                        tooltip: {
                              trigger: 'axis',
                              axisPointer: {
                                    type: 'cross',
                                    crossStyle: {
                                      color: '#999'
                                    }
                              },
                              formatter: function (params: any) {
                                    const toolTipHtml =     `<div style="color:${params[0].color}">${params[0].seriesName}: ${params[0].value}</div>
                                                             <div style="color:${params[1].color}">${params[1].seriesName}: ${params[1].value}</div>
                                                             <div style="color:${params[2].color}">${params[2].seriesName}: ${params[2].value}</div>
                                                             <div style="color:${params[3].color}">${params[3].seriesName}: ${DateUtils.toHHMMSS_string( (params[3].value * 60))}</div>
                                                             <div style="color:${params[4].color}">${params[4].seriesName}: ${params[4].value}</div>   
                                                             <div style="color:${params[5].color}">${params[5].seriesName}: ${params[5].value}</div>                                                         
                                                            `;
                                                            
                                    return toolTipHtml;
                              }
                        },
                        grid: {
                              left: '200',
              
                            },
                        toolbox: {
                              feature: {
                                dataView: { show: true, readOnly: false },
                                magicType: { show: true, type: ['line', 'bar'] },
                                restore: { show: true },
                                saveAsImage: { show: true },
                              //  dataZoom: {show: true}
                              }
                        },
              
                            legend: {
                              data: ['Frames', 'Hours', 'Minutes per frame', 'Accumulative Avg', 'Premiere', 'Alloy'],
                              show: true,
                              icon: 'circle',

                              width: "auto", 
                              height: "auto",
                              orient: "horizontal",
                              align: "auto",
                              itemWidth: 30, // correct this
                              itemHeight: 10,
                              itemGap: 10,
       
                            },
                        xAxis: [
                              {
                                type: 'category',
                                axisTick: {
                                    alignWithLabel: true
                                  },
                                data: days
                              }
                            ],
                            dataZoom: [
                              {
                                  id: 'dataZoomX',
                                  type: 'slider',
                                  xAxisIndex: [0],
                                  filterMode: 'filter'
                              },
                              {
                                  id: 'dataZoomY',
                                  type: 'slider',
                                  yAxisIndex: [0],
                                  filterMode: 'empty'
                              }
                          ],
                        yAxis: [ 
                              {
                                    type: 'value',
                                    name: 'Total Frames',
                                    position: 'right',
                                    offset: 100,
                                    alignTicks: true,
                                    axisLine: {
                                          show: true,
                                          lineStyle: {
                                                color: colors.frames
                                          }
                                    },
                                    axisLabel: {
                                          formatter: '{value} Frames' 
                                    }
                              },
                              {
                                    type: 'value',
                                    name: 'Total Hours',
                                    position: 'right',
                                    alignTicks: true,
                                    axisLine: {
                                          show: true,
                                          lineStyle: {
                                                color:  colors.hours
                                          }
                                    },
                                    axisLabel: {
                                          formatter: function (value, index) {
                                                return DateUtils.toHHMMSS_string( (value * 60));
                                            }
                                    }
                              },

                              {
                                    id: 'FramePerMinute',
                                    type: 'value',
                                    name: 'Minutes Per Frame',
                                    position: 'left',
                                    min: 0,
                                    max: 100,
                                    interval: 5,
                                    alignTicks: true,
                                    axisLine: {
                                      show: true,
                                      lineStyle: {
                                        color: colors.averageTimePerDay
                                      },
                     
                                    },
                                    axisLabel: {
                                     formatter: '{value}' 
                                    }
                               },
                               
                              {
                                    id: 'AccumulateAverage',
                                    type: 'value',
                                    name: 'Accumulative Avg',
                                    position: 'left',
                                    offset: 0,
                                    min: 0,
                                    max: 100,
                                    interval: 5,
                                    show: false,
                                    alignTicks: true,
                                    axisLine: {
                                      show: true,
                                      lineStyle: {
                                        color: colors.accumulativeAverage
                                      },
                     
                                    },
                                    axisLabel: {
                                     formatter: '{value}' 
                                    }
                              }
                                    
                        ],
                        series: [
                              {
                                    yAxisIndex: 0,
                                    name: 'Frames',
                                    type: 'bar',
                                    color: colors.frames,
                                    data: data.map( day=>day.totalFrames)
                              },
                              {
                                    /*   yAxisIndex: 0, */
                                       name: 'Premiere',
                                       type: 'bar',
                                       color: colors.premiere,
                                       data: data.map( day=>day.totalPremiere),
                                       stack: 'x'
                                 },
                                 {
                                 /*      yAxisIndex: 0, */
                                       name: 'Alloy',
                                       type: 'bar',
                                       color: colors.alloy,
                                       data: data.map( day=>day.totalAlloy),
                                       stack: 'x'
                                 },
                              {
                                    yAxisIndex: 1,
                                    name: 'Hours',
                                    color: colors.hours,
                                    type: 'bar',
                                //    data: data.map( day=> Math.round( day.totalMinutes / 60 * 100 ) / 100)
                                      data: data.map( day=> day.totalMinutes)
                              },               
                              {
                                    yAxisIndex: 2,
                                    name: 'Minutes per frame',
                                    type: 'line',
                                    color: colors.averageTimePerDay,                                    
                                    label: {
                                          show: false,
                                          position: 'top',
                                          color: "black",
                                          fontSize: 12,
                                          formatter: function(d) {
                                            return <div>d.name</div>;
                                          }
                                    },
                                    data: data.map( day=> {
                                          if ( day.totalFrames === 0 ) {
                                                return 0; 
                                          } else {
                                                return (  Math.round(day.totalMinutes/day.totalFrames * 10) / 10 )
                                          }
                                    } )
                              },
                            {
                                    yAxisIndex: 3,
                                    name: 'Accumulative Avg',
                                    type: 'line',
                                    color: colors.accumulativeAverage,
                                    data: data.map( day=> {
                                          if ( day.accumulativeFrames === 0 ) {
                                                return 0; 
                                          } else {
                                                return (  Math.round(day.accumulativeMinutes/day.accumulativeFrames * 10) / 10 )
                                          }
                                    })
                              },
                              

                        ] as SeriesOption[]
                  } as EChartsOption;

                  setEBarChartOptions(chart);
            }
            getData()

      }, [data]);

      useEffect(() => {
            if (userExcludeList.length > 0 ) {
              const ids = userExcludeList.filter( u=> u.userId !== '').map( u=>u.userId ).join(',')
              setExcludeUserIdParams( `&exclude=${ids}` );
            } else {
                  setExcludeUserIdParams( '' );
            }
            
      }, [userExcludeList.length]);

      function excludeIncludeUser( user: User, exclude: boolean ) {

            setUserExcludeList( users => {

                  if ( !users ) {
                        users = [];
                  }
                  // user should be excluded .. add to list 
                  if ( exclude ) {
                        if ( !users.includes( user)) {
                              users!.push( user )
                        }
                        const  x = [...users!];
                        return x; 
                  } else {                 
                        let people = users.filter( u => u.userId !== user.userId)
                        return people!; 
                  } 
   
            })
      }

      return (
            <div className='report' >
                  
                  <div className="calendar-selector">
                        <div className='flex flex-row pl-5'>

                              <DateRangePicker startDateCallback={(dateString)=> {
                                                                        setStartDate( DateTime.fromISO( dateString ));
                                                                  }} 
                                                endDateCallback={(dateString) => {
                                                      setEndDate( DateTime.fromISO( dateString ));
                                                 }} />


                              <div className="card flex justify-content-center m-4">
                                    <div className="flex flex-wrap gap-4">
                                          <div style={{fontSize:"15px", color:"white", fontWeight:"700"}}> Group By:</div>                              
                                    { rangeOptions && rangeOptions.map ( option => {
                                          return (
                                          <div className="range-radio-options flex align-items-center">
                                                <RadioButton style={{color:"white"}} inputId={option} name="range" value={option} onChange={(e: RadioButtonChangeEvent) => setRange(e.value)} checked={range === option } />
                                                <label htmlFor={option} className="ml-2">{option}</label>
                                          </div>
                                          )

                                    })}
                                    </div>
                        </div>
                        </div>
                  </div>


                  <Panel className={"body-panel"}>
                        <div className="report-title-wrapper">
                              <div className="report-title">Average Time Per Frame (paychex time) </div>  
                              <div className="report-date-title">{startDate && startDate.toFormat('MMM dd yyyy')} to {endDate && endDate.toFormat('MMM dd yyyy') } </div>                      
                        </div>

                        {error && <div>An error occurred loading the report.</div>}

                        {loading && <div> <ProgressSpinner /> Loading...</div>}

                        {data && data.length === 0 &&
                              <div>No frames were completed on this day</div>
                        }

                        {eBarChartOptions && 

                              <div style={{ width: "100%", height:"500px"}}>
                                    <ReactECharts option={eBarChartOptions} style={{height: "90%", width: "100%"}}/>
                              </div>

                        }
                  
     
                        <UserListView startDate={startDate} endDate={endDate} excludeIncludeUser={(user, exclude)=> excludeIncludeUser( user, exclude)} />



                  </Panel>
            </div>
      )
}

export default AverageTimePerFrameReport;
